/* src/ComingSoon.css */
  .coming-soon__container {
    display: flex;
    flex-direction: row;
  }
  
  .coming-soon__content {
    display: flex;
    flex-direction: column;
  }

