.drops-main-container img{
  width: 200px;
  height: 200px;
}

.drops-main-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}



@media screen and (max-width:600px) {
  .drops-main-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  
  
}

