.selected__drop__details__container__mobile {
  display: none;
}

/* administrator main container */
.admin__main__container {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  justify-content: flex-start;
  position: fixed;

  /* overflow-x: hidden; */
}

.admin__drop__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em;
  width: 88vw;
}

.admin__drop__container h1 {
  align-self: flex-start;
}

.test-amc {
  align-self: center;
  justify-self: center;
  text-align: center;
}

/* Daily Progress */
.daily__main__container {
  display: flex;
  flex-direction: column;
  width: 88vw;
  margin: 2em 0 0 1em;
}

.daily__main__container h2 {
  align-self: flex-start;
}

.daily__numbers__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2em;
}

.daily__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.todo_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7em;
  height: 7em;
  background-color: var(--yellow);
  border-radius: 7em;
}

.dropped__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7em;
  height: 7em;
  background-color: var(--secondary);
  border-radius: 7em;
}

.inprogress__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7em;
  height: 7em;
  background-color: var(--inprogress);
  border-radius: 7em;
}

/* Select Drop */
.select__drop__mc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.select__drop__search__c {
  display: flex;
  flex-direction: row;
  margin: 1em 0em;
  gap: 1em;
}

.select__drop__search__c input {
  border-width: 0px;
  padding: 1em;
  background-color: var(--bg-2);
  min-width: 10em;
  border-radius: 0.5em;
}

.select__drop__search__c input::placeholder {
  font-family: "Comfortaa", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-size: 0.75rem;
  margin: 0;
}

.select__drop__search__c input:focus {
  outline: 0px;
}

.select__drop__main__container__mobile {
  display: none;
}
/* Administrator dashboard drop table */
.drop__table {
  display: block;
  width: 85%;
  border-collapse: collapse;
  overflow-y: auto;
  height: 50vh;
  margin-left: 3%;
}

.drop__table thead {
  position: sticky;
}

.drop__table th {
  padding: 1em;
}

.drop__table td {
  padding: 0.75em;
  text-align: center;
}

.drop-table-body tr {
  cursor: pointer;
}

.drop-table-body tr:hover {
  background-color: var(--secondary);
}

.drop__table thead {
  background-color: var(--bg-2);
}

.drop__table__row {
  background-color: var(--bg-1);
}

.drop-table-status {
  margin: 0;
  padding: 0.5em 1em;
  border-radius: 1em;
}

.drop-status-todo {
  background-color: var(--yellow);
}

.drop-status-inprogress {
  background-color: var(--inprogress);
}

.drop-status-completed {
  background-color: var(--secondary);
}
.selected__drop__main__container {
  display: flex;
  flex-direction: row;
}

.selected__drop__container {
  display: flex;
  flex-direction: column;
  margin: 1em 0 0 1em;
}

.selected__drop__heading p {
  text-align: center;
  padding: 0.5em;
  border-radius: 1em;
  max-width: 50%;
  align-self: flex-start;
  margin: 1em 0 0 0;
}

.selected__drop__heading {
  display: flex;
  flex-direction: column;
}

.selected__drop__details__container {
  display: flex;
  flex-direction: row;
  margin: 2em 0 0 0;
  gap: 3em;
}

.selected__drop__details {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.selected__drop__details p {
  margin: 0 0 0 1em;
}

.selected__drop__images {
  display: flex;
  flex-direction: column;
}

.selected__drop__images img {
  height: 150px;
  width: 150px;
  border-radius: 1em;
}

.selected__drop__images h2 {
  grid-column: 1/3;
}

.selected__drop__image{
  display: flex;
  flex-direction: row;
  gap:1em;
}

.selected__drop__image__1 {
  display: block;
}
.selected__drop__image__2 {
  grid-column: 3/4;
  grid-row: 2/3;
}
.selected__drop__image__3 {
  grid-column: 2/3;
  grid-row: 3/4;
}
.selected__drop__image__4 {
  grid-column: 3/4;
  grid-row: 3/4;
}

.address__lines {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.selected__drop__images__share {
  grid-column: 1/2;
  grid-row: 4/5;
  display: flex;
  flex-direction: row;
  gap: 0.3em;
}

.selected__drop__images__share__buttons {
  grid-column: 1/4;
  grid-template-rows: 1/5;
  display: flex;
  gap: 1em;
}

.selected__drop__images__share__buttons button {
  border-width: 0em;
  padding: 1em;
  border-radius: 0.5em;
  background-color: var(--bg-1);
}

.selected__drop__images__share__buttons button:hover {
  background-color: var(--secondary);
}

/* Driver Management  - Page 2 */
.driver__management__container {
  display: flex;
  flex-direction: row;
  top: 1em;
}

.driver__management__content {
  display: flex;
  flex-direction: column;
  margin: 1em;
}

/* Driver Registration */
.driver__registration__container {
  display: flex;
  flex-direction: column;
  margin: 2em 0 0 1em;
}

.driver__registration_details {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-template-rows: repeat(3, 1fr);
  column-gap: 1em;
  row-gap: 1em;
  margin-top: 2em;
}

.driver__registration_details input {
  border-width: 0px;
  background-color: var(--bg-2);
}

.driver__registration_details button {
  grid-column: 4/5;
  width: 75%;
  justify-self: flex-end;
}

.d__reg__driver__name {
}

.d__reg__driver__contact {
}

.d__reg__driver__password {
}

.d__reg__driver__confirm__password {
}

.driver__registration__mobile__container {
  display: none;
}

/*Edit/Remove Driver */
.edit__driver__container {
  display: flex;
  flex-direction: column;
  margin: 2em 0 0 1em;
}

.edit__driver__details {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-template-rows: repeat(6, 1fr);
  column-gap: 1em;
  row-gap: 1em;
  margin: 2em 0 0 0;
}

.edit__driver__details p {
  align-self: center;
  justify-self: flex-end;
}

.edit__driver__selection {
  grid-column: 1/5;
  align-self: flex-end;
}

.e__dr__btn__remove__driver {
  grid-column: 3/5;
  width: 50%;
}

.e__dr__btn__change__info {
  grid-column: 3/5;
  width: 50%;
  justify-self: flex-end;
}

.e__dr__driver__name {
}

.edit__driver__mobile__container {
  display: none;
}

/* Contact Administrator */
.administrator__contact__container {
  display: flex;
  flex-direction: row;
  -ms-overflow-style: none;
  overflow-y: hidden;
  position: fixed;
}

.administrator__contact__container::-webkit-scrollbar {
  display: none;
}
.administrator__contact__container__mob {
  display: none;
}
.messaging__desk {
  display: flex;
  margin: 1em;
}

.administrator__contact__container h1 {
}

.administrator__choose__driver {
  display: flex;
  flex-direction: column;
  width: 20vw;
}

.administrator__message__choose {
  display: flex;
  flex-direction: column;
  background-color: var(--bg-2);
  padding: 1em 0;
  border-radius: 1em;
  margin: 1em 0;
  height: 60vh;
  overflow-y: auto;
}

.broadcast-active {
  background-color: var(--secondary) !important;
}

.broadcast-deactive {
}

.administrator__message__choose::-webkit-scrollbar {
  display: none;
}

.administrator__message__choose button {
  border-width: 0px;
  padding: 1em;
  background-color: var(--bg-2);
}

.administrator__message__choose button:hover {
  background-color: var(--secondary);
}

.administrator__messaging__container {
  /* display: grid;
  grid-template-columns: 2em auto auto 2em;
  column-gap: 2em;
  margin: 4em 1em 1em 1em;
  padding: 3em;
  height: fit-content;
  border: 1px solid black;
  border-radius: 1em;
  width: 68vw;
  justify-content: center;
  align-items: center; */
  display: flex;
  flex-direction: column;
  height: 65vh;
  width: 48vw;
  border: 1px solid black;
  border-radius: 1em;
  overflow-y: auto;

  padding: 1em;
  margin: 1em 1em 1em 1em;
}

.amc-chatName {
  margin: 2em 0em 0em 1em;
}

.ad__mes__reciver {
  display: flex;
  flex-direction: row;
  gap: 1em;
}
.ad__ms__sender {
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.sender {
  background-color: var(--bg-1);
  height: fit-content;
  padding: 1em;
  border-radius: 1em;
  width: fit-content;
  margin-bottom: 1em;
  margin-bottom: 1em;
  text-align: right;
  align-self: flex-end;
  margin-right: 2em;
  right: 0;
}

.reciever {
  background-color: var(--secondary);
  height: fit-content;
  padding: 1em;
  border-radius: 1em;
  width: fit-content;
  margin-bottom: 1em;
  text-align: left;
}

.broadcast{
  display: none;
}

.margin-top {
  margin-top: 2em;
}

/* Administrator Settings */
.administrator__settings__container {
  display: flex;
  flex-direction: row;
}

.administrator__settings__content {
  display: flex;
  flex-direction: column;
}
.administrator__settings__content h1 {
  margin: 1em 0 0 0.5em;
}

.administrator__settings__content h2 {
  margin: 1em 0 0 1em;
}

.administrator__settings__content input {
  margin: 1em 0 0 1em;
}

.administrator__settings__content button {
  width: 50%;
  align-self: flex-end;
  margin: 1em 0 0 0;
}

/* Help page */
.help-mc {
  display: flex;
}

.help-input-container {
  margin: 1em;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}

.help-inputs {
  display: flex;
  flex-direction: column;
  gap: 0.7em;
}
.m__wm__button__send {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1em 0 0;
  border-width: 0px;
  width: 3.5em;
  height: 3.5em;
  border-radius: 3.5em;
  transform: (background-color 0.5s ease-in-out);
}

.m__wm__button__send:hover{
  background-color: #707070;
  color: white;
cursor: pointer;
  
}


@media (max-width: 1280px) {
  .daily__numbers__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2em;
    width: 70vw;
  }

  .select__drop__search__c {
      display: flex;
      flex-direction: row;
      margin: 1em 0em;
      gap: 1em;
      width: 70vw;
   
}

.drop__table {
  display: block;
  width: 82%;
  border-collapse: collapse;
  overflow-y: auto;
  height: 50vh;
  margin-left: 3%;
}

.driver__registration__container {
  display: flex;
  flex-direction: column;
  margin: 2em 0 0 1em;
  width: 60%;
}

.edit__driver__container {
  display: flex;
  flex-direction: column;
  margin: 0.4em 0 0 0.5em;
  width: 95%;
}

}

@media (max-width: 1024px) {
  .select__drop__search__c input{
    font-size: 0.75rem;
  }

  .select__drop__search__c {
    display: flex;
    flex-direction: row;
    margin: 1em 0em;
    gap: 0.2em;
    width: 90vw;
 
}

.side__panel__item .heading-3 {
  font-size: 0.84rem;
}

.drop__table {
  display: block;
  width: 82%;
  border-collapse: collapse;
  overflow-y: auto;
  height: 50vh;
  margin-left: 0;
  margin-right: auto; 

}

.drop__table .heading-2 {
  font-size: 0.85em;
}

.drop-table-body tr {
  font-size: 0.83em;
}


.drop__table th {
  padding: 0.8em;
}

.drop__table td {
  padding: 0.60em;
  text-align: center;
}

.side__panel__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  left: 0 !important;
  max-width: 12vw;
  font-size: 1.1em;
  min-width: 10.4em;
  height: 100dvh;
  background-color: var(--bg-1);
  padding: 0.1em;
}

.administrator__messaging__container {
  display: flex;
  flex-direction: column;
  height: 65vh;
  width: 40vw;
  border: 1px solid black;
  border-radius: 1em;
  overflow-y: auto;
  padding: 1em;
  margin: 1em 1em 1em 1em;
}

.message__writter__container__desk {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 44vw;
  gap: 1em;
  align-self: start;
  justify-self: flex-start;
}



}


@media (max-width: 800px) {

  .side__panel__container {
    display: none;
  }

  .drop__table {
    display: block;
    width: 100%;
    border-collapse: collapse;
    overflow-y: auto;
    height: 50vh;
    margin-left: 3%;
    margin-right: auto;
}

.drop-table-body tr {
  font-size: 0.88em;
}

.select__drop__search__c {
  display: flex;
  flex-direction: row;
  margin: 1em 0em;
  gap: 0.2em;
  width: 82vw;
}

.administrator__messaging__container {
  display: flex;
  flex-direction: column;
  height: 65vh;
  width: 47vw;
  border: 1px solid black;
  border-radius: 1em;
  overflow-y: auto;
  padding: 1em;
  margin: 1em 1em 1em 1em;
}

.help-input-container {
  margin: 1em;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  margin-left: 30%;
}

}

@media (max-width: 768px) {
  .select__drop__mc {
    margin: 0 2em 0 2em;
  }

  
}

@media (max-width: 800px) {

  /* Daily Progress */
  .select__drop__mc {
    display: none;
  }

  .admin__drop__container {
    width: 100vw;
  }

  .daily__numbers__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2em;
    width: 80vw;
}
  .daily__main__container h2 {
    margin: 0 auto !important;
    position: relative;
    text-align: center;
    left: auto;
    justify-self: center;
    align-self: center;
    padding-right: 12%;
  }

  /* Select Drop */
  .select__drop__main__container__mobile {
    display: flex;
    flex-direction: column;
    margin-right: 7%;
  }

  .select__drop__search__bars {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
    gap: 1em;
  }
  .select__drop__cards__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
  }

  .select__drop__tag {
    width: fit-content;
    padding: 0.2em 0.7em;
    border-radius: 0.5em;
  }

  .select__drop__tag__todo {
    background-color: var(--yellow);
  }

  .select__drop__tag__ip {
    background-color: var(--inprogress);
  }
  .select__drop__tag__done {
    background-color: var(--secondary);
  }

  .sdcc__Link {
    text-decoration: none;
    color: black;
  }

  .select__drop__card {
    display: flex;
    flex-direction: row;
    justify-content: center;

    background-color: var(--bg-1);
    width: 80vw;
    padding: 1em;
    margin: 0.5em;
    gap: 3em;
    border-radius: 0.5em;
  }

  .select__drop__card__col {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  .col1 {
    text-align: left;
  }

  .col2 {
    text-align: right;
  }

  /* Selected Drop */
  .selected__drop__main__container {
    display: none;
  }

  .selected__drop__details__container__mobile {
    margin: 1em 0 0 1em;
    display: flex;
    flex-direction: column;
  }

  .selected__drop__details__container__mobile h2 {
    margin-left: 0.5em;
    margin-top: 1em;
  }

  .s__d__d__tagline {
    width: fit-content;
    margin: 1em 0 0 1em;
  }

  .selected__drop__details__sc {
    margin: 1em 0 0 1em;
    gap: 1em;
  }

  /* Driver Management */
  /* Driver Registration */
  .driver__registration__container {
    display: none;
  }

  .edit__driver__container {
    display: none;
  }

  .driver__management__container {
    flex-direction: column;
    height: 120vh;
  }

  .driver__registration__mobile__container {
    display: flex;
    flex-direction: column;
  }
  .driver__registration__mobile__container h2 {
    margin: 1em 0 0 1em;
  }
  .dr__reg__form__mob {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
    margin: 1em;
  }
  .dr__reg__form__mob button {
    align-self: center;
    width: 40%;
  }
  /* Edit Driver */
  .edit__driver__mobile__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .edit__driver__mobile__container h2 {
    margin: 1em 0 0 1em;
  }
  .ed__dr__form__mob {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
  }
  .ed__dr__form__mob p {
    margin-top: 1em;
  }
  .ed__dr__form__mob__select {
    display: flex;
    flex-direction: row;
    gap: 1em;
  }
  /* Contact Administrator */
  
  .administrator__messaging__container {
    display: flex;
    flex-direction: column;
    height: 65vh;
    width: 55vw;
    border: 1px solid black;
    border-radius: 1em;
    overflow-y: auto;
    padding: 1em;
    margin: 1em 1em 1em 1em;
}

.administrator__choose__driver {
  display: flex;
  flex-direction: column;
  width: 30vw;
}

.message__writter__container__desk {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 60vw;
  gap: 1.5em;
  align-self: start;
  justify-self: flex-start;
}

  .administrator__message__choose__mob {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 1em;
    gap: 0.5em;
    background-color: var(--bg-2);
    border-radius: 1em;
    padding: 1em;
  }

  .administrator__message__choose__mob Link {
    width: 100%;
  }

  .administrator__message__choose__mob button {
    display: flex;
    flex-direction: column;
    border-width: 0px;
    border-radius: 1em;
    padding: 1em 2em;
    background-color: var(--bg-2);
    text-align: left;
    cursor: pointer;
    width: 100%;
  }

  .administrator__message__choose__mob button:hover {
    background-color: var(--secondary);
  }

  .a__m__cm__line {
    height: 2px;
    background-color: #707070;
  }

  .mobile-msg-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  /* Individual Messaging Component */
  .administrator__messaging__container__mob {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin: 1em;
  }

  .reciever__mob {
    display: flex;
    background-color: var(--secondary);
    padding: 1em;
    width: fit-content;
    align-self: flex-start;
    border-radius: 1em;
  }

  .reciever__mob h3 {
    background-color: var(--bg-1);
    padding: 1em;
    border-radius: 1em;
  }

  .sender__mob {
    display: flex;
    border-radius: 1em;

    gap: 1em;
    background-color: var(--bg-1);
    padding: 1em;
    width: fit-content;
    align-self: flex-end;
  }

  .sender__mob h3 {
    background-color: var(--bg-1);
    padding: 1em;
    border-radius: 1em;
  }

  .message__writter__container__mob {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: right;
    width: 100%;
    gap: 1em;
    position: fixed;
    bottom: 5em;
  }

  .message__writter__mob {
    border: 0px solid black;
    width: 60%;
    align-self: flex-end;
    padding: 1.5em;
    background-color: var(--bg-2);
    border-radius: 1em;
  }

  .message__writter__mob:focus {
    outline: none;
  }

  .m__wm__button__send {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1em 0 0;
    border-width: 0px;
    width: 3.5em;
    height: 3.5em;
    border-radius: 3.5em;
  }

  .m__wm__button__send:hover{
    color: #707070;

  }

  /* Administrator settings */

  .administrator__settings__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1em;
  }
  .administrator__settings__content {
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .administrator__settings__content h1 {
    align-self: flex-start;
    margin: 0;
  }

  .administrator__settings__content h2 {
    margin: 3em 0 0 1em;
  }

  .administrator__settings__content button {
    justify-self: center;
    align-self: center;
  }
  .as__change__password {
    margin-top: 4em !important;
  }

  .side__panel__container {
    display: none;

  }

  .administrator__settings__content {
    display: flex;
    flex-direction: column;
    margin-left: 1%;
}

}


@media (max-width: 600px) {

  /* Daily Progress */
  .select__drop__mc {
    display: none;
  }

  .admin__drop__container {
    width: 100vw;
  }

  .daily__numbers__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.5em;
    width: 75vw;
}
  .daily__main__container h2 {
    margin: 0 auto !important;
    position: relative;
    text-align: center;
    left: auto;
    justify-self: center;
    align-self: center;
    padding-right: 12%;
  }

  /* Select Drop */
  .select__drop__main__container__mobile {
    display: flex;
    flex-direction: column;
    margin-right: 7%;
  }

  .select__drop__search__bars {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
    gap: 1em;
  }
  .select__drop__cards__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
  }

  .select__drop__tag {
    width: fit-content;
    padding: 0.2em 0.7em;
    border-radius: 0.5em;
  }

  .select__drop__tag__todo {
    background-color: var(--yellow);
  }

  .select__drop__tag__ip {
    background-color: var(--inprogress);
  }
  .select__drop__tag__done {
    background-color: var(--secondary);
  }

  .sdcc__Link {
    text-decoration: none;
    color: black;
  }

  .select__drop__card {
    display: flex;
    flex-direction: row;
    justify-content: center;

    background-color: var(--bg-1);
    width: 80vw;
    padding: 1em;
    margin: 0.5em;
    gap: 3em;
    border-radius: 0.5em;
  }

  .select__drop__card__col {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  .col1 {
    text-align: left;
  }

  .col2 {
    text-align: right;
  }

  /* Selected Drop */
  .selected__drop__main__container {
    display: none;
  }

  .selected__drop__details__container__mobile {
    margin: 1em 0 0 1em;
    display: flex;
    flex-direction: column;
  }

  .selected__drop__details__container__mobile h2 {
    margin-left: 0.5em;
    margin-top: 1em;
  }

  .s__d__d__tagline {
    width: fit-content;
    margin: 1em 0 0 1em;
  }

  .selected__drop__details__sc {
    margin: 1em 0 0 1em;
    gap: 1em;
  }

  /* Driver Management */
  /* Driver Registration */
  .driver__registration__container {
    display: none;
  }

  .edit__driver__container {
    display: none;
  }

  .driver__management__container {
    flex-direction: column;
    height: 120vh;
  }

  .driver__registration__mobile__container {
    display: flex;
    flex-direction: column;
  }
  .driver__registration__mobile__container h2 {
    margin: 1em 0 0 1em;
  }
  .dr__reg__form__mob {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
    margin: 1em;
  }
  .dr__reg__form__mob button {
    align-self: center;
    width: 50%;
  }
  /* Edit Driver */
  .edit__driver__mobile__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .edit__driver__mobile__container h2 {
    margin: 1em 0 0 1em;
  }
  .ed__dr__form__mob {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
  }
  .ed__dr__form__mob p {
    margin-top: 1em;
  }
  .ed__dr__form__mob__select {
    display: flex;
    flex-direction: row;
    gap: 1em;
  }
  /* Contact Administrator */
  .messaging__desk {
    display: none;
  }
  .administrator__contact__container__mob {
    margin: 1em;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .administrator__message__choose__mob {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 1em;
    gap: 0.5em;
    background-color: var(--bg-2);
    border-radius: 1em;
    padding: 1em;
  }

  .administrator__message__choose__mob Link {
    width: 100%;
  }

  .administrator__message__choose__mob button {
    display: flex;
    flex-direction: column;
    border-width: 0px;
    border-radius: 1em;
    padding: 1em 2em;
    background-color: var(--bg-2);
    text-align: left;
    cursor: pointer;
    width: 100%;
  }

  .administrator__message__choose__mob button:hover {
    background-color: var(--secondary);
  }

  .a__m__cm__line {
    height: 2px;
    background-color: #707070;
  }

  .mobile-msg-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  /* Individual Messaging Component */
  .administrator__messaging__container__mob {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin: 1em;
  }

  .reciever__mob {
    display: flex;
    background-color: var(--secondary);
    padding: 1em;
    width: fit-content;
    align-self: flex-start;
    border-radius: 1em;
  }

  .reciever__mob h3 {
    background-color: var(--bg-1);
    padding: 1em;
    border-radius: 1em;
  }

  .sender__mob {
    display: flex;
    border-radius: 1em;

    gap: 1em;
    background-color: var(--bg-1);
    padding: 1em;
    width: fit-content;
    align-self: flex-end;
  }

  .sender__mob h3 {
    background-color: var(--bg-1);
    padding: 1em;
    border-radius: 1em;
  }

  .message__writter__container__mob {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: right;
    width: 85vw;
    gap: 0.4em;
    position: fixed;
    bottom: 5em;
  }

  .message__writter__mob {
    border: 0px solid black;
    width: 60%;
    align-self: flex-end;
    padding: 1.5em;
    background-color: var(--bg-2);
    border-radius: 1em;
  }

  .message__writter__mob:focus {
    outline: none;
  }

  .m__wm__button__send {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1em 0 0;
    border-width: 0px;
    width: 3.5em;
    height: 3.5em;
    border-radius: 3.5em;
  }

  /* Administrator settings */

  .administrator__settings__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1em;
  }
  .administrator__settings__content {
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .administrator__settings__content h1 {
    align-self: flex-start;
    margin: 0;
  }

  .administrator__settings__content h2 {
    margin: 3em 0 0 1em;
  }

  .administrator__settings__content button {
    justify-self: center;
    align-self: center;
  }
  .as__change__password {
    margin-top: 4em !important;
  }

  .side__panel__container {
    display: none;

  }

  .administrator__settings__content {
    display: flex;
    flex-direction: column;
    margin-left: 1%;
}

}

@media (max-width: 450px) {

  .message__writter__container__mob {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: right;
    width: 95vw;
    gap: 0.4em;
    position: fixed;
    bottom: 5em;
    margin-right: 10%;
  }

}

@media (max-width: 500px) {

  .help-input-container {
    margin: 1em;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    margin-left: 17%;
  }

}