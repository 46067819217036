/* Side Panel  - Administrator*/
.side__panel__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  left: 0 !important;
  max-width: 12vw;
  font-size: 1em;
  min-width: 10em;
  height: 100dvh;
  background-color: var(--bg-1);
  padding: 2em;

}

/* Bottom Panel */
.bottom__panel__container {
  display: none;
}

.side__panel__desktop {
  display: flex;
}

.side__panel__menu {
  margin: 3em 0 0 0;
}

.side__panel__menu h5 {
  margin: 0 0 1.5em 0;
}

.side__panel__Link {
  text-decoration: none;
}

.side__panel__menu button {
  border-width: 0px;
  background-color: var(--bg-1);
}

.side__panel__others {
  margin: 3em 0 0 0;
}

.side__panel__others h5 {
  margin: 0 0 1.5em 0;
}

.side__panel__item {
  display: flex;
  flex-direction: row;
  gap: 0.7em;
  margin: 0 0 0.7em 0;
  padding: 0.75em;
  border-radius: 0.5em;
  border-width: 0px;
  background-color: var(--bg-1);
  text-decoration: none;
  cursor: pointer;
}

.side__panel__item:hover {
  background-color: var(--secondary);
}

.active__side__panel__item {
  background-color: var(--primary) !important;
  color: white;
}

@media (max-width: 600px) {
  .side__panel__container {
    display: none;
  }

  .bottom__panel__container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100vw;
    background-color: var(--bg-1);
    position: fixed;
    align-items: center;
    text-align: center;
    bottom: 0;
    height: 4em;
  }

  .bottom__panel__container button {
    background-color: var(--bg-1);
    border-width: 0px;
    
  }

  .bottom__panel__icon {
    color: var(--secondary);
    margin: 0 auto;
    cursor: pointer;
  }

  .bottom-panel-clicked{
    color:var(--primary) !important;
  } 

  .bottom__panel__icon:hover {
    color: var(--primary);
  }


  .loading-main{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;

  }


}

@media (max-width: 800px) {
  .side__panel__container {
    display: none;
  }

  .bottom__panel__container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100vw;
    background-color: var(--bg-1);
    position: fixed;
    align-items: center;
    text-align: center;
    bottom: 0;
    height: 4em;
  }

  .bottom__panel__container button {
    background-color: var(--bg-1);
    border-width: 0px;
    
  }

  .bottom__panel__icon {
    color: var(--secondary);
    margin: 0 auto;
    cursor: pointer;
  }

  .bottom__panel__icon:hover {
    color: var(--primary);
  }
}
