.login__main__container {
  display: flex;
  flex-direction: row;
  height: 100dvh;
}

.login__form__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100%-3em);
  gap: 1em;
  background-color: var(--bg-1);
  padding: 3em;
  flex: 1;
}

.login__form {
    display: flex;
    flex-direction: column;

}

.l__f__email {
  display: flex;
  flex-direction: column;
}

.login__form h4 {
  margin-bottom: 0.5em;
}

.login__form input {
  margin-bottom: 1em;
}

.login__form p {
  text-align: end;
  text-decoration: underline;
}

.login__form button {
  margin: 0 auto;
  background-color: var(--primary);
  color: white;
  margin-top: 2em;
  padding: 1em 5em;
}

.login__form button:hover{
    background-color: var(--secondary);
    color: black;
}

.l__f__password {
}

.login__image__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 3;
 
}

.login__image__container video{
  width: 50%;

}

@media (max-width:1024px){

  .login__main__container{
    flex-direction: column;

  }

  .login__form__container{
    min-height: 100dvh;
  }

  .login__image__container{
    display: none;
  }

}
