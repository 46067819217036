.drop-edit-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.drop-edit-top {
  width: 100vw;
  padding-top: 4em;
  padding-bottom: 2em;
  background-color: var(--primary);
  border-bottom-left-radius: 2em;
  border-bottom-right-radius: 2em;
}

.drop-edit-top h3 {
  margin-left: 20px;
  color: white;
}

.drop-edit-top p {
  margin-left: 20px;
  color: white;
}

.drop-edit-datetime {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  margin: 1em;
  padding: 0.5em;
}

.drop-edit-location {
  align-self: flex-start;
  margin-left: 20px;
}

.drop-edit-comment {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  gap: 1em;
}

.drop-edit-comment-textarea {
  width: 90%;
  height: 10em;
}

.drop-edit-comment textarea {
}
