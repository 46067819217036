@media screen and (max-width: 600px) {
  .driver-sign-in-main-container {
    display: flex;
    flex-direction: column;
    max-width: 450px;
    margin: 1em;
    height: 100dvh;
    gap: 1em;
  }

  .driver-sign-in-main-container img {
    width: 200px;
    align-self: center;
  }

  .dsi-title {
    display: flex;
    flex-direction: row;
    gap: 1em;
  }

  .dsi-title button {
    background-color: white;
    border: none;
  }

  .button-sign-in {
    background-color: #056617;
    color: white;
  }

  /* toggle button */
  .App {
    font-family: sans-serif;
    text-align: center;
  }

  .switch {
    width: 50px;
    height: 20px;
    background-color: rgba(166, 200, 169, 0.4);
    display: flex;
    justify-content: flex-start;
    border-radius: 50px;
    padding: 10px;
    cursor: pointer;
  }

  .switch[data-isOn="true"] {
    justify-content: flex-end;
  }

  .handle {
    width: 20px;
    height: 20px;
    background-color: #056617;
    border-radius: 40px;
  }

  /* sign in button */
  .sign-in-button {
    display: flex;
    align-self: center;
    justify-self: center;
  }
}
