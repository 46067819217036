.administrator-messages {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.administrator__messaging__container {
    display: flex;
    flex-direction: column;
    height: 65vh;
    width: 50vw;
    border: 1px solid black;
    border-radius: 1em;
    overflow-y: auto;
    padding: 1em;
    margin: 1em 1em 1em 1em;
}

.message__writter__container__desk {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    gap: 1.5em;
    align-self: start;
    justify-self: flex-start;
}


@media (max-width: 1024px) {
    .administrator__messaging__container {
        display: flex;
        flex-direction: column;
        height: 65vh;
        width: 45vw;
        border: 1px solid black;
        border-radius: 1em;
        overflow-y: auto;
        padding: 1em;
        margin: 1em 1em 1em 1em;
    }

    .message__writter__container__desk {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 55vw;
        gap: 1.5em;
        align-self: start;
        justify-self: flex-start;
    }

}

@media (max-width: 800px) {
    .administrator__messaging__container {
        display: flex;
        flex-direction: column;
        height: 65vh;
        width: 55vw;
        border: 1px solid black;
        border-radius: 1em;
        overflow-y: auto;
        padding: 1em;
        margin: 1em 1em 1em 1em;
    }

    .message__writter__container__desk {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 63vw;
        gap: 1.5em;
        align-self: end;
        justify-self: flex-end;
    }

}